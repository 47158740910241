function input() {

    var input = $('.input input');

    input
        .on('focusout', function() {
            if (!$(this).val()) {
                $(this).parent().removeClass('_focused');
            }
        })
        .on('focusin', function() {
            $(this).parent().addClass('_focused');
        })
        .on('change', function(){
            var el = $(this);
            if (el.val()) {
                el.parent().addClass('_focused');
            } else {
                el.parent().removeClass('_focused');
            }
        });
}