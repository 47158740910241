function languages() {
    var $languageToggle = $('._js-toggle-language');

    $(document).on('click', '._js-toggle-language', function() {
        $(this).toggleClass('_toggled');
    });

    // outside
    $(document).on('click', function(e) {
        if($(e.target).closest('._js-language, ._js-toggle-language').length) return

        $languageToggle.removeClass('_toggled');
    });
}
