function modalWindow(id) {

    if (id.length) {

        id.bPopup({
            positionStyle: 'fixed',
            easing: 'easeOutBack',
            speed: 450,
            opacity: .75,
            modalColor: 'transparent',
        });

    }

}