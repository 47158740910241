function mm_modal(id) {

    if (id.length) {

        id.bPopup({
            contentContainer:'.mm_news_detail',
            // loadUrl: '/news/'+id+'?ajax=Y', //Uses jQuery.load()
            positionStyle: 'fixed',
            position: ['', 0],
            follow: [false, false],
            easing: 'easeOutBack',
            speed: 450,
            opacity: .75,
            modalColor: '#000000',
            scrollBar: true,
            onOpen: function() {
                var width = $('body').width();
                var height = $('body').height();
                $('body').addClass('_hidden');
                // console.log(id);
                $('body').css({
                    'width' : width,
                    'height' : height
                });

                var _news_detail_slider = $('#_news_detail_slider');

                if (_news_detail_slider.size()) {
                    _news_detail_slider.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        arrows: true,
                        prevArrow: '<button type="button" class="slick-prev"><span class="i _prev"></span></button>',
                        nextArrow: '<button type="button" class="slick-next"><span class="i _next"></span></button>',
                        adaptiveHeight: true
                    });
                }

            },
            onClose: function() {
                $('body').removeClass('_hidden');
                $('body').css({
                    'width' : 'auto'
                });
                //history.pushState('', document.title, window.location.pathname);
            }
        });
    }

}