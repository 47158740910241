function inputFile() {

    var input        = $('._js-input-file'),
        addFile      = $('._js-upload-file'),
        fileName     = $('._js-file-name');

    input.hide();
    addFile.on('click', function(){
        input.trigger('click');
    });

    input.on('change', function(){
        if ( $(this).val ) {
            var uploadedFileName = this.files[0].name;
            fileName.text(uploadedFileName);
        } else {
            fileName.text('');
        }
    });

}